import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PropsWithChildren, Suspense } from "react";
import CenterSpinner from "../sharedComponents/CenterSpinner";
import { useIsRestoring } from "@tanstack/react-query";
import useAuth from "../store/auth/authState";
import { useIsMobile } from "../hooks/useIsMobile";
import AccountSettingsPage from "./accountAndSettings/components/AccountSettingsPage";
import Profile from "./accountAndSettings/components/Profile";
import Billing from "./accountAndSettings/components/Billing";
import Settings from "./accountAndSettings/components/Settings";

const Login = React.lazy(() => import("./auth/components/Login.page"));
const SignUp = React.lazy(() => import("./auth/components/SignUp.page"));
const ResetPassword = React.lazy(
  () => import("./auth/components/ResetPassword.page"),
);

const CategoriesPage = React.lazy(
  () => import("./categories/components/desktop-layout/CategoriesGrid.page"),
);

const MobileCategoriesPage = React.lazy(
  () =>
    import("./categories/components/mobile-layout/MobileCategoriesGrid.page"),
);

const Main = React.lazy(() => import("./main/components/Main.page"));
// const HomePage = React.lazy(() => import("./home/components/Home.page"))
// const ContactPage = React.lazy(() => import("./contact/components/Contact.page"));

const GuardedRoute = ({ children }: PropsWithChildren) => {
  const { tokens } = useAuth();
  const isRestoring = useIsRestoring();
  if (isRestoring) return <CenterSpinner />;
  if (!tokens.accessToken) return <Navigate to="/auth/sign-in" replace />;
  return <>{children}</>;
};

const LoadingRoute = ({ children }: PropsWithChildren) => {
  const isRestoring = useIsRestoring();
  if (isRestoring) return <CenterSpinner />;
  return <>{children}</>;
};

const Router = () => {
  const { isMobile } = useIsMobile();
  return (
    <Suspense fallback={<CenterSpinner />}>
      <Routes>
        <Route path="/auth/sign-in" element={<Login />} />
        <Route path="/auth/sign-up" element={<SignUp />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route
          path="/account/*"
          element={
            <GuardedRoute>
              <AccountSettingsPage />
            </GuardedRoute>
          }
        >
          <Route
            path="info"
            element={
              <GuardedRoute>
                <Profile />
              </GuardedRoute>
            }
          />
          <Route
            path="billing"
            element={
              <GuardedRoute>
                <Billing />
              </GuardedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <GuardedRoute>
                <Settings />
              </GuardedRoute>
            }
          />
        </Route>
        <Route
          path="/categories"
          element={
            <LoadingRoute>
              {isMobile ? <MobileCategoriesPage /> : <CategoriesPage />}
            </LoadingRoute>
          }
        />
        <Route
          path="/"
          element={
            <LoadingRoute>
              {isMobile ? <MobileCategoriesPage /> : <CategoriesPage />}
            </LoadingRoute>
          }
        />
        {/* <Route
          path="/contactUs"
          element={
            <LoadingRoute>
              <ContactPage />
            </LoadingRoute>
          }
        /> */}
      </Routes>
    </Suspense>
  );
};

export default Router;
