import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSettings } from "../services/accountSettings.service";
import { Settings } from "../models/settings.model";

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: Settings) => updateSettings(settings),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["settings"] });
    },
    onError: (error) => {
      console.error("Error updating settings", error);
    },
  });
};
