import React from "react";
import { Box, Text, VStack, HStack, Divider, Flex } from "@chakra-ui/react";

const Billing: React.FC = () => {
  // Mock billing data
  const billingData = {
    cardHolderName: "John Doe",
    cardNumber: "**** **** **** 1234",
    expiryDate: "12/25",
    billingAddress: "123 Main St, Springfield, USA",
    paymentHistory: [
      { date: "2023-07-01", amount: "$120.00", status: "Paid" },
      { date: "2023-06-01", amount: "$120.00", status: "Paid" },
      { date: "2023-05-01", amount: "$120.00", status: "Paid" },
    ],
  };

  return (
    <Box
      w={"100%"}
      h={"100%"}
      mx="auto"
      p={8}
      bg="white"
      overflow="hidden"
      mb={8}
    >
      <Text fontSize="2xl" fontWeight="bold">
        For Visual testing only !!!
      </Text>
      <VStack spacing={6} align="flex-start">
        <Text fontSize="2xl" fontWeight="bold">
          Billing Information
        </Text>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Payment Method
          </Text>
          <VStack align="flex-start" spacing={2}>
            <Text fontSize="md">
              <strong>Card Holder:</strong> {billingData.cardHolderName}
            </Text>
            <Text fontSize="md">
              <strong>Card Number:</strong> {billingData.cardNumber}
            </Text>
            <Text fontSize="md">
              <strong>Expiry Date:</strong> {billingData.expiryDate}
            </Text>
          </VStack>
        </Box>

        <Divider />

        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Billing Address
          </Text>
          <Text fontSize="md">{billingData.billingAddress}</Text>
        </Box>

        <Divider />

        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Payment History
          </Text>
          <VStack align="flex-start" spacing={2}>
            {billingData.paymentHistory.map((payment, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                width="100%"
                px={4}
                py={2}
                bg="gray.50"
                borderRadius="md"
                boxShadow="sm"
              >
                <Text fontSize="md">{payment.date}</Text>
                <Text fontSize="md">{payment.amount}</Text>
                <Text
                  fontSize="md"
                  color={payment.status === "Paid" ? "green.500" : "red.500"}
                >
                  {payment.status}
                </Text>
              </Flex>
            ))}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default Billing;
