import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  IconButton,
  Avatar,
  Button,
  Text,
  useMediaQuery,
  Link as ChakraLink,
  ButtonGroup,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import { AiFillBulb, AiOutlineBulb } from "react-icons/ai";
import Logo from "../assets/images/EYA_logo.svg";
import useAppMode from "../store/appMode/appModeState";
import { useGetProfile } from "../modules/accountAndSettings/hooks/useGetProfile";
import useAuth from "../store/auth/authState";
import useHeaderState from "../store/header/headerState";
import WelcomeModal from "./dialogs/WelcomeModal";
import EditModal from "./dialogs/EditModal";

const Header: React.FC = () => {
  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const { isEditMode, toggleEditMode } = useAppMode();
  const { data: profile } = useGetProfile();
  const { tokens } = useAuth();
  const {
    setShowProfileButton,
    setShowAiButton,
    setShowEditButton,
    showNavigationButtons,
    showProfileButton,
    showAiButton,
    showEditButton,
    triggerAiButtonClick,
    isAiButtonHighlighted,
  } = useHeaderState();
  const location = useLocation();
  const isAuthenticated = !!tokens.accessToken;
  const ml = isMobileDevice ? "8vw" : "5vw";

  const [isWelcomeModal, setISWelcomeModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    setShowProfileButton(false);
    setShowAiButton(false);
    setShowEditButton(false);

    if (location.pathname === "/categories" || location.pathname === "/") {
      if (isAuthenticated) {
        setShowAiButton(true);
        setShowProfileButton(true);
        setShowEditButton(true);
      }
    }

    // Check if the modal has already been shown
    const hasSeenDemo = localStorage.getItem("hasSeenDemo");
    if (!hasSeenDemo) {
      setISWelcomeModalOpen(true);
      localStorage.setItem("hasSeenDemo", "true");
    }
  }, [location, tokens]);

  const onEditBtnClick = () => {
    if (isAuthenticated) {
      toggleEditMode();
    } else {
      setIsEditModalOpen(true);
    }
  };

  const handleCloseWelcomeModal = () => {
    setISWelcomeModalOpen(false);
  };

  return (
    <>
      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        fontFamily="Poppins Polly, sans-serif"
        px={ml}
      >
        <Box>
          <ChakraLink as={RouterLink} to="/">
            <Flex align="center" justifyContent="center">
              <Image boxSize="110px" src={Logo} alt="EYA Logo" />
            </Flex>
          </ChakraLink>
        </Box>

        <Flex alignItems="center" justifyContent="center" flex="1">
          {showNavigationButtons && (
            <ButtonGroup spacing={4} mr="auto" ml="auto">
              <ChakraLink as={RouterLink} to="/">
                <Button size="lg" colorScheme="teal" variant="ghost">
                  Home
                </Button>
              </ChakraLink>
              <ChakraLink as={RouterLink} to="/categories">
                <Button size="lg" colorScheme="teal" variant="ghost">
                  Categories
                </Button>
              </ChakraLink>
              <ChakraLink as={RouterLink} to="/contactUs">
                <Button size="lg" colorScheme="teal" variant="ghost">
                  Contact Us
                </Button>
              </ChakraLink>
            </ButtonGroup>
          )}
        </Flex>

        <Flex alignItems="center">
          {showAiButton && (
            <Button
              size="lg"
              variant="outline"
              colorScheme="orange"
              borderColor="orange"
              borderWidth="2px"
              onClick={() => triggerAiButtonClick(true)}
            >
              {isAiButtonHighlighted ? (
                <AiFillBulb size="24px" color="orange" fill="orange" />
              ) : (
                <AiOutlineBulb size="24px" color="orange" />
              )}
            </Button>
          )}
          {showEditButton && (
            <IconButton
              icon={
                isEditMode ? (
                  <CheckIcon boxSize="32px" />
                ) : (
                  <EditIcon boxSize="32px" />
                )
              }
              aria-label={isEditMode ? "Done" : "Edit"}
              boxSize="48px"
              ml={4}
              variant="ghost"
              onClick={onEditBtnClick}
              mr={4}
            />
          )}
          {showProfileButton && profile && (
            <ChakraLink
              as={RouterLink}
              to="/account/info"
              _hover={{ textDecoration: "none" }}
            >
              <Flex alignItems="center" ml={4} cursor="pointer">
                <Avatar size="md" name={profile.name} src={profile.avatarUrl} />
                <Text ml={2} fontWeight="bold" size={"lg"}>
                  {profile.name}
                </Text>
              </Flex>
            </ChakraLink>
          )}
          {!isAuthenticated && (
            <>
              <ChakraLink as={RouterLink} to="/auth/sign-in">
                <Button
                  size="lg"
                  flexDirection="column"
                  colorScheme="teal"
                  variant="outline"
                  mr={4}
                >
                  Sign In
                </Button>
              </ChakraLink>
            </>
          )}
        </Flex>
      </Flex>
      <WelcomeModal
        isOpen={isWelcomeModal}
        onClose={handleCloseWelcomeModal}
        isAuthenticated={isAuthenticated}
      />
      <EditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
    </>
  );
};

export default Header;
