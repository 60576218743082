/* eslint-disable no-undef */
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "../store";
import { clearTokensAction } from "../store/auth/authState";

// Base URL from environment variables
const baseURL = process.env.REACT_APP_API_URL as string;

// Create Axios instance with default configuration
const axiosInstance: AxiosInstance = axios.create({
  baseURL,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error({ error });
    const status = error?.response?.status;
    const errorMessage = error?.response?.data?.message;

    // Handle specific error cases
    if (
      status === 401 &&
      (errorMessage === "Token is expired" || errorMessage === "Unauthorized")
    ) {
      //TODO: navigate to sign in screen
      store.dispatch(clearTokensAction());
      window.location.href = "/sign-in";
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
export type { AxiosResponse, AxiosRequestConfig };
