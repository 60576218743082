import axios, { AxiosResponse, AxiosRequestConfig } from "./axios";

export type DataResponse<T> = {
  data: T;
  nextCursor?: string;
};

const postRequest = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(url, data, config);
  } catch (err) {
    throw err;
  }
};

const getRequest = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const patchRequest = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<any> => {
  try {
    const response = await axios.patch(url, data, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteRequest = async (
  url: string,
  config?: AxiosRequestConfig,
): Promise<any> => {
  try {
    const response = await axios.delete(url, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const putRequest = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<any> => {
  try {
    const response = await axios.put(url, data, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  postRequest,
  getRequest,
  deleteRequest,
  patchRequest,
  putRequest,
};
