import React from "react";
import { Box, VStack, Button, Spacer, Divider, Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useLogoutMutation } from "../../auth/hooks/useLogoutMutation";

const Sidebar: React.FC = () => {
  const { mutate: logout } = useLogoutMutation();

  const handleLogout = () => {
    logout(); // Call the logout mutation
  };

  return (
    <Box
      width="230px"
      bg="gray.100"
      height="100%"
      display="flex"
      flexDirection="column"
      p="6"
    >
      <VStack spacing="3" align="stretch" flex="1">
        <NavLink to="info">
          <Button width="100%">Profile</Button>
        </NavLink>
        {/* <Flex justifyContent={"center"} alignItems={"center"}>
          <Divider width={"60%"} borderColor="grey" opacity={"50%"} />
        </Flex>
        <NavLink to="billing">
          <Button width="100%">Billing</Button>
        </NavLink> */}
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Divider width={"60%"} borderColor="grey" opacity={"50%"} />
        </Flex>
        <NavLink to="settings">
          <Button width="100%">Settings</Button>
        </NavLink>
        <Spacer />
        <Button
          width="100%"
          onClick={handleLogout}
          color={"white"}
          backgroundColor={"teal.500"}
          _hover={{ backgroundColor: "teal.600" }}
        >
          Logout
        </Button>
      </VStack>
    </Box>
  );
};

export default Sidebar;
