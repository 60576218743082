import React from "react";
import { Box, Flex, CloseButton } from "@chakra-ui/react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const AccountSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    const currentPath = location.pathname;

    if (
      currentPath.startsWith("/auth/settings") ||
      currentPath.startsWith("/auth/info")
    ) {
      navigate("/");
    } else if (currentPath.startsWith("/categories")) {
      navigate("/categories");
    } else if (currentPath.startsWith("/contactUs")) {
      navigate("/contactUs");
    } else {
      navigate("/");
    }
  };

  return (
    <Flex height="70vh" px={48} mt={24}>
      <Flex boxShadow={"lg"} w={"100%"} position="relative">
        <Sidebar />
        <CloseButton
          position="absolute"
          top="4"
          right="4"
          onClick={handleClose}
        />
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default AccountSettingsPage;
