import networkAgent from "../../../services/network-agent.service";

export const loginUser = async (email: string, password: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/login";
  return networkAgent.postRequest(url, { email, password });
};

export const logoutUser = async (userId: string) => {
  const url = process.env.REACT_APP_API_URL + "/auth/logout";
  return networkAgent.postRequest(url, { userId });
};
