import React, { useState, useEffect } from "react";
import {
  Box,
  Switch,
  Text,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Divider,
  Button,
  Flex,
  Input,
  NumberInput,
  NumberInputField,
  Spinner,
} from "@chakra-ui/react";
import { useGetSettings } from "../hooks/useGetSettings";
import { useUpdateSettings } from "../hooks/useUpdateSettings";

const Settings: React.FC = () => {
  const { data: settings, isLoading, error } = useGetSettings();
  const { mutate: updateSettings } = useUpdateSettings();

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState<{
    theme: string;
    notificationsEnabled: boolean;
    language: string;
    eyeTrackingMode: boolean;
    focusTime: number;
    wakeWord: string;
    showCategoryImage: boolean;
  }>({
    theme: "",
    notificationsEnabled: false,
    language: "",
    eyeTrackingMode: false,
    focusTime: 0,
    wakeWord: "",
    showCategoryImage: true,
  });

  useEffect(() => {
    if (settings) {
      setFormData({
        theme: settings.theme || "light",
        notificationsEnabled: settings.notificationsEnabled || true,
        language: settings.language || "us",
        eyeTrackingMode: settings.eyeTrackingMode || true,
        focusTime: settings.focusTime || 2000,
        wakeWord: settings.wakeWord || "Eya",
        showCategoryImage: settings.showCategoryImage || true,
      });
    }
  }, [settings]);

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleNumberChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      focusTime: parseInt(value, 10) || 0,
    }));
  };

  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  const saveChanges = () => {
    updateSettings(formData);
    setIsEditMode(false);
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return <Text color="red.500">Error fetching settings data</Text>;
  }

  return (
    <Box
      w={"100%"}
      h={"100%"}
      mx="auto"
      p={8}
      bg="white"
      overflow="hidden"
      mb={8}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Text fontSize="2xl" fontWeight="bold" mb={6}>
          Settings
        </Text>
        <Stack spacing={6}>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="theme" mb="0" flex="1">
              Theme
            </FormLabel>
            <Select
              id="theme"
              name="theme"
              value={formData.theme}
              onChange={handleChange}
              isDisabled={!isEditMode}
              width="150px"
            >
              <option value="light">Light</option>
              <option value="dark">Dark</option>
            </Select>
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="notificationsEnabled" mb="0" flex="1">
              Notifications
            </FormLabel>
            <Switch
              id="notificationsEnabled"
              name="notificationsEnabled"
              isChecked={formData.notificationsEnabled}
              onChange={handleChange}
              isDisabled={!isEditMode}
              size="md"
              width="50px"
            />
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="language" mb="0" flex="1">
              Language
            </FormLabel>
            <Select
              id="language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              isDisabled={!isEditMode}
              width="150px"
            >
              <option value="us">English</option>
              <option value="ua">Ukrainian</option>
            </Select>
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="eyeTrackingMode" mb="0" flex="1">
              Eye Tracking Mode
            </FormLabel>
            <Switch
              id="eyeTrackingMode"
              name="eyeTrackingMode"
              isChecked={formData.eyeTrackingMode}
              onChange={handleChange}
              isDisabled={!isEditMode}
              size="md"
              width="50px"
            />
          </FormControl>

          {formData.eyeTrackingMode && (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="focusTime" mb="0" flex="1">
                Focus Time (ms)
              </FormLabel>
              <NumberInput
                id="focusTime"
                name="focusTime"
                value={formData.focusTime}
                onChange={handleNumberChange}
                isDisabled={!isEditMode}
                width="150px"
                min={0}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          )}

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="wakeWord" mb="0" flex="1">
              Wake Word
            </FormLabel>
            <Input
              id="wakeWord"
              name="wakeWord"
              value={formData.wakeWord}
              onChange={handleChange}
              isDisabled={!isEditMode}
              width="150px"
            />
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="showCategoryImage" mb="0" flex="1">
              Show Category Image
            </FormLabel>
            <Switch
              id="showCategoryImage"
              name="showCategoryImage"
              isChecked={formData.showCategoryImage}
              onChange={handleChange}
              isDisabled={!isEditMode}
              size="md"
              width="50px"
            />
          </FormControl>
        </Stack>
      </Box>
      <Flex justifyContent="flex-end" mt={6}>
        {isEditMode && (
          <Button onClick={saveChanges} colorScheme="teal" mr={4}>
            Save Changes
          </Button>
        )}
        <Button
          onClick={toggleEditMode}
          colorScheme={isEditMode ? "red" : "teal"}
        >
          {isEditMode ? "Cancel" : "Edit"}
        </Button>
      </Flex>
    </Box>
  );
};

export default Settings;
