import networkAgent from "../../../services/network-agent.service";
import { Profile } from "../models/profile.model";
import { Settings } from "../models/settings.model";

export const fetchProfile = async (): Promise<Profile> => {
  const url = `${process.env.REACT_APP_API_URL}/profiles`;
  return networkAgent.getRequest<Profile>(url);
};

export const updateProfile = async (data: Profile) => {
  const url = `${process.env.REACT_APP_API_URL}/profiles`;
  return networkAgent.patchRequest(url, data);
};

export const fetchSettings = async () => {
  const url = `${process.env.REACT_APP_API_URL}/settings`;
  return networkAgent.getRequest<Settings>(url);
};

export const updateSettings = async (data: Settings) => {
  const url = `${process.env.REACT_APP_API_URL}/settings`;
  return networkAgent.patchRequest(url, data);
};
