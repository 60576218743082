import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Flex,
  Image,
  IconButton,
  CloseButton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  isAuthenticated: boolean;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  isOpen,
  onClose,
  isAuthenticated,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      text: "Welcome to the Categories page!",
      image: "https://via.placeholder.com/800x400.png?text=Step+1", // Placeholder image, replace with actual image
    },
    {
      text: "Here you can browse different categories.",
      image: "https://via.placeholder.com/800x400.png?text=Step+2",
    },
    {
      text: "Click on a category to view its content.",
      image: "https://via.placeholder.com/800x400.png?text=Step+3",
    },
    {
      text: "Use the 'Edit' button to modify or add new categories.",
      image: "https://via.placeholder.com/800x400.png?text=Step+4",
    },
    {
      text: "You can use the search bar to quickly find a category by name.",
      image: "https://via.placeholder.com/800x400.png?text=Step+5",
    },
    {
      text: "Toggle the 'View' button to switch between grid and list views for a better browsing experience.",
      image: "https://via.placeholder.com/800x400.png?text=Step+6",
    },
    {
      text: "Hover over a category to see additional options like 'Delete' or 'Move'.",
      image: "https://via.placeholder.com/800x400.png?text=Step+7",
    },
    {
      text: "Use the 'Filter' option to view only specific types of categories or those with certain properties.",
      image: "https://via.placeholder.com/800x400.png?text=Step+8",
    },
    {
      text: "Click on 'Add New Category' to create a new category from scratch or based on an existing template.",
      image: "https://via.placeholder.com/800x400.png?text=Step+9",
    },
    {
      text: "Use the drag-and-drop feature to reorder categories based on your preference.",
      image: "https://via.placeholder.com/800x400.png?text=Step+10",
    },
    {
      text: "Don't forget to save your changes after editing or adding new categories!",
      image: "https://via.placeholder.com/800x400.png?text=Step+11",
    },
    {
      text: "Use the 'Undo' button if you need to revert a recent action.",
      image: "https://via.placeholder.com/800x400.png?text=Step+12",
    },
    {
      text: "Enable or disable category visibility by toggling the 'Hidden' switch.",
      image: "https://via.placeholder.com/800x400.png?text=Step+13",
    },
    {
      text: "Access more settings from the 'Settings' menu to customize the categories page layout.",
      image: "https://via.placeholder.com/800x400.png?text=Step+14",
    },
    {
      text: "Enjoy exploring and managing your categories effortlessly!",
      image: "https://via.placeholder.com/800x400.png?text=Step+15",
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>
          How to Use Categories
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Box position="relative">
            <Carousel
              selectedItem={currentStep}
              onChange={setCurrentStep}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop
              interval={5000}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    boxSize={12}
                    aria-label={label}
                    icon={<ArrowBackIcon />}
                    onClick={onClickHandler}
                    position="absolute"
                    left="25px"
                    top="45%"
                    transform="translateY(-50%)"
                    zIndex={2}
                    variant="ghost"
                    bg="whiteAlpha.700"
                  />
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    boxSize={12}
                    aria-label={label}
                    icon={<ArrowForwardIcon />}
                    onClick={onClickHandler}
                    position="absolute"
                    right="25px"
                    top="45%"
                    transform="translateY(-50%)"
                    zIndex={2}
                    variant="ghost"
                    bg="whiteAlpha.700"
                  />
                )
              }
            >
              {steps.map((step, index) => (
                <Box key={index} p={4} textAlign="center">
                  <Image src={step.image} alt={`Step ${index + 1}`} mb={4} />
                  <Text fontSize="lg">{step.text}</Text>
                </Box>
              ))}
            </Carousel>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%" justifyContent="space-between">
            {!isAuthenticated ? (
              <>
                <Button colorScheme="teal" onClick={onClose}>
                  Continue as Guest
                </Button>
                <Flex>
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    as={RouterLink}
                    to="/auth/sign-in"
                    mr={3}
                  >
                    Log In
                  </Button>
                  <Button colorScheme="teal" as={RouterLink} to="/auth/sign-up">
                    Sign Up
                  </Button>
                </Flex>
              </>
            ) : (
              <Button colorScheme="teal" onClick={onClose}>
                Continue
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal;
