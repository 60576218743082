import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface EditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Restricted Access</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg" mb={4}>
            You need to be logged in to use the edit button and access all the
            functionalities of EYA.
          </Text>
          <Text>
            Please sign in to continue or create a new account if you don't have
            one yet.
          </Text>
        </ModalBody>

        <ModalFooter>
          <RouterLink to="/auth/sign-in">
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Sign In
            </Button>
          </RouterLink>
          <RouterLink to="/auth/sign-up">
            <Button variant="outline" onClick={onClose}>
              Sign Up
            </Button>
          </RouterLink>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
